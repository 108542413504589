import React from 'react'
import 'semantic-ui-css/semantic.min.css'
import { Message, Icon, Grid } from 'semantic-ui-react'
import { BrowserRouter as Router } from 'react-router-dom'

import { ParallaxProvider } from 'react-scroll-parallax'
import timestamp from 'time-stamp'
import Nav from './component/nav/Nav'
import NavMobile from './component/nav/NavMobile'

import { isMobileOnly } from 'react-device-detect'
import PcPadVersion from './diffApp/PcPadVersion'
import MobileVersion from './diffApp/MobileVersion'
import ScrollToTop from './ScrollToTop'
//redux
// import store from './redux/store'
// import { Provider } from 'react-redux'
// const root = ReactDOM.createRoot(document.getElementById('root'))

const userLang = navigator.language || navigator.userLanguage

class App extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      log: '',
      logExpire: false,
      windowWidth: 1440,
      windowHeight: 420
    }
    this.closeMessage = this.closeMessage.bind(this)
  }

  componentDidMount () {
    const log = localStorage.getItem('login')
    const hello = process.env.REACT_APP_Hello
    console.log(hello)
    const self = this
    self.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    })
    window.addEventListener(
      'resize',
      function (event) {
        // console.log(event)
        self.setState({
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight
        })
      },
      true
    )

    this.setState({
      log: log
    })
    var now = timestamp.utc('YYYY-MM-DD-HH')

    var year = now.substring(0, 4)
    var month = now.substring(5, 7)
    var day = now.substring(8, 10)
    var hour = now.substring(11, 13)

    year = parseInt(year)
    month = parseInt(month)
    day = parseInt(day)
    hour = parseInt(hour)

    var loginTime = localStorage.getItem('loginTime')

    console.log(this, window)

    window.document.addEventListener(
      'onchange',
      function () {
        loginTime = localStorage.getItem('loginTime')
        if (loginTime !== null && loginTime !== undefined) {
          var logYear = loginTime.substring(0, 4)
          var logMonth = loginTime.substring(5, 7)
          var logDay = loginTime.substring(8, 10)
          var logHour = loginTime.substring(11, 13)

          logYear = parseInt(logYear)
          logMonth = parseInt(logMonth)
          logDay = parseInt(logDay)
          logHour = parseInt(logHour)
          // console.log(day > logDay)
          if (year > logYear) {
            localStorage.clear()
            localStorage.setItem('logExpire', true)
            localStorage.setItem('log', false)
            localStorage.setItem('login', false)
            localStorage.setItem('merchantLogin', false)
            this.setState({
              log: false,
              logExpire: true
            })
          } else if (year === logYear) {
            if (month > logMonth) {
              localStorage.clear()
              localStorage.setItem('logExpire', true)
              localStorage.setItem('log', false)
              localStorage.setItem('login', false)
              localStorage.setItem('merchantLogin', false)

              this.setState({
                log: false,
                logExpire: true
              })
            } else if ((month = logMonth)) {
              if (day > logDay) {
                localStorage.clear()
                localStorage.setItem('logExpire', true)
                localStorage.setItem('log', false)
                localStorage.setItem('login', false)
                localStorage.setItem('merchantLogin', false)

                this.setState({
                  log: false,
                  logExpire: true
                })
              } else if (day === logDay) {
                if (hour - logHour > 24) {
                  localStorage.clear()
                  localStorage.setItem('logExpire', true)
                  localStorage.setItem('log', false)
                  localStorage.setItem('login', false)
                  localStorage.setItem('merchantLogin', false)

                  this.setState({
                    log: false,
                    logExpire: true
                  })
                } else {
                  localStorage.setItem('logExpire', false)
                  this.setState({
                    logExpire: false
                  })
                }
              }
            }
          }
        }
      },
      false
    )

    if (loginTime !== null && loginTime !== undefined) {
      var logYear = loginTime.substring(0, 4)
      var logMonth = loginTime.substring(5, 7)
      var logDay = loginTime.substring(8, 10)
      var logHour = loginTime.substring(11, 13)

      logYear = parseInt(logYear)
      logMonth = parseInt(logMonth)
      logDay = parseInt(logDay)
      logHour = parseInt(logHour)
      // console.log(day > logDay)
      if (year > logYear) {
        localStorage.clear()
        localStorage.setItem('logExpire', true)
        localStorage.setItem('log', false)
        localStorage.setItem('login', false)
        localStorage.setItem('merchantLogin', false)
        this.setState({
          log: false,
          logExpire: true
        })
      } else if (year === logYear) {
        if (month > logMonth) {
          localStorage.clear()
          localStorage.setItem('logExpire', true)
          localStorage.setItem('log', false)
          localStorage.setItem('login', false)
          localStorage.setItem('merchantLogin', false)

          this.setState({
            log: false,
            logExpire: true
          })
        } else if ((month = logMonth)) {
          if (day > logDay) {
            localStorage.clear()
            localStorage.setItem('logExpire', true)
            localStorage.setItem('log', false)
            localStorage.setItem('login', false)
            localStorage.setItem('merchantLogin', false)

            this.setState({
              log: false,
              logExpire: true
            })
          } else if (day === logDay) {
            if (hour - logHour > 24) {
              localStorage.clear()
              localStorage.setItem('logExpire', true)
              localStorage.setItem('log', false)
              localStorage.setItem('login', false)
              localStorage.setItem('merchantLogin', false)

              this.setState({
                log: false,
                logExpire: true
              })
            } else {
              localStorage.setItem('logExpire', false)
              this.setState({
                logExpire: false
              })
            }
          }
        }
      }
    }
  }
  closeMessage () {
    localStorage.setItem('logExpire', false)
    this.setState({
      logExpire: false
    })
  }

  render () {
    var logExpirel = localStorage.getItem('logExpire')
    var logExpire = this.state.logExpire
    const self = this

    // const breakpoint = 925
    const windWidth = this.state.windowWidth
    console.log(windWidth)
    // const windHeight = this.state.windowHeight

    return (
      <Router>
        <ScrollToTop />
        <div>
          {(isMobileOnly !== true || windWidth > 500) &&
            (logExpirel === 'true' || logExpire === true) && (
              <Grid>
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <Message>
                      <Message.Header>
                        {userLang === 'zh-CN' && '登录已失效'}
                        {userLang !== 'zh-CN' && 'Log In Expired'}
                      </Message.Header>

                      {isMobileOnly && (
                        <Icon
                          name='close'
                          circular
                          onClick={self.closeMessage}
                          style={{ style: 'relative', left: 225 }}
                        />
                      )}
                      {isMobileOnly !== true && (
                        <Icon
                          name='close'
                          circular
                          onClick={self.closeMessage}
                        />
                      )}

                      <p>
                        {userLang === 'zh-CN' && '您的登录已失效，请重新登录.'}
                        {userLang !== 'zh-CN' &&
                          'Your time has expired, log in again.'}
                      </p>
                    </Message>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
          )}

          {isMobileOnly !== true && windWidth < 1090 && (
            <NavMobile windWidth={windWidth} />
          )}
          {isMobileOnly !== true && windWidth >= 1090 && (
            <Nav windWidth={windWidth} />
          )}
          {(isMobileOnly !== true || windWidth > 500) && (
            <PcPadVersion windWidth={windWidth} />
          )}

          {isMobileOnly === true && (
            <ParallaxProvider>
              <MobileVersion windWidth={windWidth} />
            </ParallaxProvider>
          )}
        </div>
      </Router>
    )
  }
}

export default App
