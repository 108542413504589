import React from 'react'
import {
  Segment,
  Button,
  Divider,
  Form,
  Grid,
  Checkbox,
  Message,
  Portal
} from 'semantic-ui-react'
import { Link, withRouter } from 'react-router-dom'
import axios from 'axios'
// import Nav from '../nav/Nav'
import newOTP from 'otp-generators'
import emailjs from 'emailjs-com'

import Foot from '../foot/Foot'
import timestamp from 'time-stamp'
const userLang = navigator.language || navigator.userLanguage
var jwt = require('jsonwebtoken')
//live
// const baseUrl = 'https://susucloset.com/'
//test
const baseUrl = 'http://localhost:3000/'

class LoginPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      products: [],
      token: '',
      rememberMe: false,
      loading: true,
      buttonLoading: false,
      loggedIn: false,
      somethingWrong: false
      // open: false
    }
    this.onHandleUsername = this.onHandleUsername.bind(this)
    this.onHandlePassword = this.onHandlePassword.bind(this)
    this.onHandleSubmit = this.onHandleSubmit.bind(this)
    this.onHandleRememberMe = this.onHandleRememberMe.bind(this)
    this.getQueryParam = this.getQueryParam.bind(this)
  }

  componentDidMount () {
    window.scrollTo(0, 0)
    const self = this
    var loginFailure = self.props.loginFailure
    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    const urlParams = new URLSearchParams(window.location.search)
    const isClosetUser = urlParams.get('isClosetUser')

    const login = localStorage.getItem('login')
    const emailConfirmed = localStorage.getItem('emailConfirmed')
    if (login === 'true' && emailConfirmed === 'true' && !isClosetUser) {
      self.props.history.push('/mypage')
    }

    if (loginFailure === 'Wrong identifier combination') {
      self.setState({
        somethingWrong: 'Wrong identifier & password combination'
      })
    } else if (loginFailure === 'An error occured') {
      self.setState({ somethingWrong: 'Something wrong occured!' })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)
    const remember = localStorage.getItem('rememberMe')
    var identifier = localStorage.getItem('identifier')
    var password = localStorage.getItem('password')
    if (remember === 'true')
      this.setState({
        rememberMe: remember,
        username: identifier,
        password: password
      })
  }

  getQueryParam (param) {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get(param)
  }

  onHandleUsername (event) {
    this.setState({
      username: event.target.value
    })
  }

  onHandlePassword (event) {
    this.setState({
      password: event.target.value
    })
  }

  onHandleSubmit (event) {
    event.preventDefault()
    const remember = this.state.rememberMe
    const self = this
    self.setState({
      buttonLoading: true
    })

    var identifier = this.state.username
    var password = this.state.password
    var rememberMe = this.state.rememberMe

    const urlParams = new URLSearchParams(window.location.search)
    var isClosetUser = self.props.location.search
    // const isClosetUserParam = urlParams.get('isClosetUser');
    isClosetUser = isClosetUser ? isClosetUser.split('=')[1] : null
    console.log(isClosetUser)
    if (remember === 'true') {
      identifier = localStorage.getItem('identifier')
      password = localStorage.getItem('password')
      const identifierRetype = this.state.username
      const passwordRetype = this.state.password
      if (identifierRetype !== '' && passwordRetype !== '') {
        identifier = identifierRetype
        password = passwordRetype
      }
    } else {
      identifier = localStorage.getItem('identifier')
      password = localStorage.getItem('password')
      const rememberMe = localStorage.getItem('rememberMe')

      console.log(identifier, password)
      if (rememberMe !== null) {
        localStorage.removeItem('rememberMe')
      }

      if (
        (identifier === null && password === null) ||
        (identifier === '' && password === '')
      ) {
        identifier = this.state.username
        password = this.state.password
      }
    }

    axios
      .post('https://sheyou-backend.herokuapp.com/auth/local', {
        identifier: identifier,
        password: password
      })
      .then(function (res) {
        console.log(res)

        if (res.status === 200) {
          const emailConfirmed = res.data.user.confirmed
          const merchantConfirmed = res.data.user.merchantConfirmed
          self.setState({
            emailConfirmed: emailConfirmed
          })

          const token = res.data.jwt
          const email = res.data.user.email
          const username = res.data.user.username
          const id = res.data.user.id
          console.log(res.data)

          localStorage.setItem('emailConfirmed', emailConfirmed)
          localStorage.removeItem('toLog')
          const time = timestamp.utc('YYYY-MM-DD-HH')
          localStorage.setItem('loginTime', time)
          localStorage.setItem('token', token)
          localStorage.setItem('id', id)
          localStorage.setItem('identifier', identifier)
          localStorage.setItem('password', password)
          localStorage.setItem('rememberMe', rememberMe)

          if (!emailConfirmed && !merchantConfirmed) {
            const verificationCode = newOTP.generate(6, {
              alphabets: false,
              upperCase: false,
              specialChar: false
            })

            const token = jwt.sign(
              {
                data: verificationCode
              },
              'sheyoufashion',
              { expiresIn: '5min' }
            )

            emailjs
              .send(
                'service_orld5qb',
                'template_c1h9qsr',
                {
                  email: email,
                  reply_to: 'susanna.xinshu@gmail.com',
                  name: username,
                  verificationCode: verificationCode
                },
                'user_4rMSnDYBigUutDlR5RSib'
              )
              .then(
                function (response) {
                  console.log('SUCCESS!', response.status, response.text)
                  self.setState({
                    loading: false,
                    sentEmail: true
                  })

                  localStorage.setItem('identifier', identifier)
                  localStorage.setItem('verificationCode', token)
                  self.props.history.push('/emailConfirmation')
                },
                function (error) {
                  self.setState({
                    loading: false,
                    sentEmail: false,
                    somethingWrong:
                      'Email confirmation FAILED, please contact us...' +
                      error.text
                  })
                }
              )
          } else if (emailConfirmed && !merchantConfirmed) {
            if (isClosetUser) {
              localStorage.setItem('closetLogin', 'true')
            } else {
              localStorage.setItem('login', 'true')
              localStorage.setItem('log', 'true')
            }

            self.setState({
              loggedIn: true,
              buttonLoading: false
            })

            if (isClosetUser) {
              window.location.href = `${baseUrl}#/?user=${id}&success=true&token=${token}`
            } else {
              self.props.history.push('/logging')
            }
          } else if (merchantConfirmed && !emailConfirmed) {
            localStorage.removeItem('toLog')
            localStorage.setItem('login', 'true')
            localStorage.setItem('merchantLogin', 'true')
            localStorage.setItem('log', 'true')
            const time = timestamp.utc('YYYY-MM-DD-HH')
            localStorage.setItem('loginTime', time)
            const token = res.data.jwt
            localStorage.setItem('token', token)
            localStorage.setItem('identifier', identifier)
            localStorage.setItem('password', password)
            localStorage.setItem('rememberMe', rememberMe)

            self.props.history.push('/loggingMerchant')
          } else if (emailConfirmed && merchantConfirmed) {
            localStorage.removeItem('toLog')
            localStorage.setItem('login', 'true')
            localStorage.setItem('merchantLogin', 'true')
            localStorage.setItem('log', 'true')
            const time = timestamp.utc('YYYY-MM-DD-HH')
            localStorage.setItem('loginTime', time)
            const token = res.data.jwt
            localStorage.setItem('token', token)
            localStorage.setItem('identifier', identifier)
            localStorage.setItem('password', password)
            localStorage.setItem('rememberMe', rememberMe)

            if (isClosetUser) {
              window.location.href = `${baseUrl}#/?user=${id}&success=true&token=${token}`
            } else {
              self.props.history.push('/logging')
            }
          }
        } else {
          // alert('something went wrong')
          self.setState({
            rememberMe: false,
            username: '',
            password: ''
          })
          localStorage.removeItem('toLog')
          self.props.history.push('/signin/failure')
        }
      })
      .catch(error => {
        localStorage.removeItem('toLog')
        self.setState({
          buttonLoading: false,
          rememberMe: false,
          username: '',
          password: ''
        })
        if (error.response.status === 400) {
          self.setState({
            buttonLoading: false,
            somethingWrong: 'Wrong identifier & password combination'
          })
          // alert("Wrong identifier combination")
          self.props.history.push('/signin/wrong')
          localStorage.removeItem('identifier')
          localStorage.removeItem('password')
          localStorage.removeItem('rememberMe')
        } else {
          self.setState({
            buttonLoading: false,
            somethingWrong: 'An error occurred:' + error.response.statusText
          })
          self.props.history.push('/signin/failure')
          // alert('An error occurred:', error.response.statusText)
        }
      })
  }

  onHandleResetPassword (event) {
    this.props.history.push('/forgetpassword')
  }

  onHandleRememberMe (event) {
    const self = this
    const remember = self.state.rememberMe
    if (remember !== 'true') {
      self.setState({ rememberMe: true })
    } else {
      self.setState({ rememberMe: false })
    }
  }

  render () {
    console.log(this.state)
    const self = this
    const remember = this.state.rememberMe
    const identifier = this.state.username
    const password = this.state.password
    const windowWidth = this.props.windWidth
    console.log(windowWidth)
    var isClosetUser = self.props.location.search
    // const isClosetUserParam = urlParams.get('isClosetUser');
    isClosetUser = isClosetUser ? isClosetUser.split('=')[1] : null
    console.log(77777, isClosetUser)

    // const urlParams = new URLSearchParams(window.location.search)
    // const isClosetUser = urlParams.get('isClosetUser')

    const backgroundStyle = isClosetUser
      ? {
          height: '100vh',
          backgroundImage:
            "url('https://res.cloudinary.com/sheyou/image/upload/v1727875894/virtual_closet_5d66ccc54e.svg')",
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }
      : {
          height: '100vh'
        }

    return (
      <div>
        {/* <Nav /> */}
        <div className='gradient_limeade' style={backgroundStyle}>
          <Segment placeholder>
            <Grid columns={windowWidth < 655 ? 1 : 2} centered>
              <Grid.Column>
                {this.state.loggedIn === true && (
                  <Message positive>
                    <Message.Header>
                      {userLang === 'zh-CN' ? '成功' : 'Success'}
                    </Message.Header>
                    <p>
                      {userLang === 'zh-CN'
                        ? '您已成功登陆'
                        : 'Now You Are Logged In'}
                    </p>
                  </Message>
                )}
                {this.state.somethingWrong !== false && (
                  <Message negative>
                    <Message.Header>
                      {userLang === 'zh-CN' ? '登陆失败' : 'Log In Failure'}
                    </Message.Header>
                    <p>
                      {this.state.somethingWrong ===
                      'Wrong identifier & password combination'
                        ? userLang === 'zh-CN'
                          ? '用户名和密码错误或者不匹配'
                          : 'Wrong identifier & password combination'
                        : userLang === 'zh-CN'
                        ? '出现未知问题:('
                        : this.state.somethingWrong}
                    </p>
                    {userLang !== 'zh-CN' &&
                      localStorage.removeItem(identifier)}
                  </Message>
                )}
                {this.state.emailConfirmed === false && userLang !== 'zh-CN' && (
                  <Message warning>
                    <Message.Header>Email not confirmed</Message.Header>
                    <p>You will get an email for confirmation</p>
                  </Message>
                )}

                <Form>
                  <Form.Input
                    onChange={this.onHandleUsername}
                    icon='user'
                    iconPosition='left'
                    label={
                      userLang === 'zh-CN'
                        ? '用户名 / 邮箱'
                        : 'Username / email'
                    }
                    placeholder='Username / email'
                    value={remember === 'true' ? identifier : undefined}
                  />

                  <Form.Input
                    onChange={this.onHandlePassword}
                    icon='lock'
                    iconPosition='left'
                    label={userLang === 'zh-CN' ? '密码' : 'Password'}
                    type='password'
                    value={remember === 'true' ? password : undefined}
                  />

                  <Form.Field>
                    <Checkbox
                      defaultChecked={remember === 'true'}
                      color='orange'
                      label={userLang === 'zh-CN' ? '记住我' : 'Remember Me'}
                      onClick={this.onHandleRememberMe}
                    />
                  </Form.Field>
                </Form>
                <br />
                <br />
                <Grid stackable>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Button
                        loading={this.state.buttonLoading}
                        onClick={this.onHandleSubmit}
                        content={userLang === 'zh-CN' ? '登录' : 'Log In'}
                        color='orange'
                        fluid
                      />
                    </Grid.Column>

                    <Grid.Column>
                      <Link to='/forgetpassword'>
                        <Button
                          basic
                          content={
                            userLang === 'zh-CN'
                              ? '忘记密码'
                              : 'Forget Password'
                          }
                          color='orange'
                          fluid
                        />
                      </Link>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>

              {windowWidth < 655 && (
                <Divider horizontal style={{ width: '60vw' }}>
                  Or
                </Divider>
              )}

              <Grid.Column verticalAlign='middle'>
                <Link to={`/signup?isClosetUser=${isClosetUser}`}>
                  <Button
                    className='ml-auto'
                    content={userLang === 'zh-CN' ? '注册' : 'Sign up'}
                    icon='signup'
                    size='big'
                    color='orange'
                    fluid
                  />
                </Link>
              </Grid.Column>
            </Grid>

            {windowWidth >= 655 && <Divider vertical>Or</Divider>}
          </Segment>
        </div>

        {/* <Portal open={this.state.open}>
          <Segment
            style={{
              left: '40%',
              position: 'fixed',
              top: '50%',
              zIndex: 1000
            }}
          >
            <Header>
              You have both personal and merchant account, please choose one to
              log in
            </Header>

            <Button
              content='Personal Account'
              color='teal'
              onClick={self.toPersonal}
            />
            <Button
              content='Merchant Account'
              color='orange'
              onClick={self.toMerchant}
            />
          </Segment>
        </Portal> */}

        <Foot />
      </div>
    )
  }
}

export default withRouter(LoginPage)
