import React from 'react'
import {
  Grid,
  Menu,
  Header,
  Button,
  Segment,
  Icon
} from 'semantic-ui-react'
import { Parallax } from 'react-parallax'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'

import Foot from '../../foot/Foot'
import axios from 'axios'

import MerchantHome from './MerchantHome'
import MerchantStore from './MerchantStore'
import MerchantProduct from './MerchantProduct'
import NewProduct from './NewProduct'
import EditProduct from './EditProduct'
import ToSaleProduct from './ToSaleProduct'
import MerchantOrder from './MerchantOrder'

class MerchantPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      login: false,
      userInfo: [],
      userID: '',
      merchantVerified: false,
      merchantInfo: {},

      loading: true
    }
    this.handleItemClick = this.handleItemClick.bind(this)
    // this.handleGoVerify = this.handleGoVerify.bind(this)
    this._isMounted = false;
  }

  componentDidMount () {
    window.scrollTo(0, 0)
    const self = this
    const p = this.props
    const activeItem = p.activeItem

    self.setState({ activeItem: activeItem })

    const merchantLogin = localStorage.getItem('merchantLogin')
    if (merchantLogin !== 'true') {
      self.props.history.push('/merchantsLog')
      console.log(merchantLogin)
    }

    this.ifLog()

    console.log(p)
    if (activeItem === 'Membership') {
      self.setState({
        activeItem: 'Membership'
      })
    }

    this._isMounted = true;

    window.setTimeout(() => {
      if (this._isMounted && self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  ifLog () {
    const log = localStorage.getItem('merchantLogin')
    const self = this
    if (log === 'false') {
      // self.setState({ login: false })
      const token = localStorage.getItem('token')
      console.log(token)
    } else {
      self.setState({ login: true })
      this.getUserInfo()
    }
  }

  getUserInfo () {
    const self = this
    const token = localStorage.getItem('token')
    self.setState({ loading: true })
    axios('https://sheyou-backend.herokuapp.com/users/me', {
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (res) {
      console.log(res)
      const userInfo = [res.data]
      console.log(userInfo)
      if (userInfo === null) {
        self.setState({
          userInfo: [],
          userID: userInfo[0].id,
          loading: false
        })
      } else if (userInfo.length > 0) {
        if (self._isMounted) {
          self.setState({
            merchantInfo: userInfo[0].merchantInfo,
            userInfo: userInfo,
            userID: userInfo[0].id,
            loading: false
          })
        }
      }

      // const merchantInfo = userInfo[0].merchantInfo
      // console.log(merchantInfo)
      // if((merchantInfo !== null) && (merchantInfo.merchantVerified === true)){
      //     self.setState({merchantVerified: true})
      // }else{
      //   self.setState({merchantVerified: false})
      // }
    })
  }

  // handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  handleItemClick (event, data) {
    var name = data.name
    const link = data.to
    // const self = this
    // window.location.assign('https://sheyoufashion.com/#' + link)
    if (name === undefined || name === null) {
      this.props.history.push('/merchantpage')
      window.location.reload(true)
      console.log(name, link)
    } else {
      this.props.history.push(link)
      console.log(name,link)
      window.location.reload(true)
    }
  }

  render () {
    const s = this.state
    const loading = s.loading
    const activeItem = this.state.activeItem
    const login = s.login
    console.log(s.merchantVerified)

    return (
      <div>
        {/* <Nav /> */}

        {loading === true && (
          <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>

            <Grid.Row colums={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                <Header size='huge'>
                  <Icon loading name='asterisk' size='massive' color='yellow' />
                  Loading...
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        )}

        {loading === false && login === false && (
          <Parallax
            bgImage='https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png'
            bgImageAlt=''
            strength={200}
          >
            <div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <Segment>
                <Grid>
                  <Grid.Row centered>
                    <Header> You Should login first</Header>
                  </Grid.Row>

                  <Grid.Row centered>
                    <Link to='/merchantsLog'>
                      <Button size='huge' color='orange'>
                        Login
                      </Button>
                    </Link>
                  </Grid.Row>
                </Grid>
              </Segment>
            </div>

            <div style={{ height: '200px' }} />
          </Parallax>
        )}

        {loading === false && login !== false && login !== 'false' && (
          <Grid columns={2} style={{ paddingTop: 10 }}>
            <Grid.Column width={3} style={{ paddingLeft: 50 }}>
              <Menu secondary vertical>
                {/* <Menu.Item>
                  <Image
                    size='mini'
                    style={{ position: 'relative', left: 15, paddingBottom: 5 }}
                    src='https://res.cloudinary.com/sheyou/image/upload/v1647579043/_265d4956e0.png?140123.80000007153'
                  />
                  <p style={{ color: '#767676' }}>Merchants</p>
                  
                </Menu.Item> */}

                <Menu.Item header color='yellow'>
                  Merchants
                </Menu.Item>
                <Menu.Item
                  name='Home'
                  to='/merchantpage'
                  active={activeItem === 'Home'}
                  onClick={this.handleItemClick}
                >
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={5}>Home</Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Menu.Item>

                <Menu.Item
                  name='Store'
                  to='/merchantpage/store'
                  active={activeItem === 'Store'}
                  onClick={this.handleItemClick}
                >
                  <Grid.Column>Store</Grid.Column>
                </Menu.Item>

                <Menu.Item header color='yellow'>
                  Upload
                </Menu.Item>

                <Menu.Item
                  name='products'
                  to='/merchantpage/products'
                  active={activeItem === 'products'}
                  onClick={this.handleItemClick}
                >
                  <Grid.Column>Clothing</Grid.Column>
                </Menu.Item>

                <Menu.Item
                  name='accessaries'
                  to='/merchantpage/accessaries'
                  active={activeItem === 'accessaries'}
                  onClick={this.handleItemClick}
                >
                  <Grid.Column>Accessory</Grid.Column>
                </Menu.Item>

                <Menu.Item
                  name='sale'
                  to='/merchantpage/sale'
                  active={activeItem === 'sale'}
                  onClick={this.handleItemClick}
                >
                  <Grid.Column>Sale</Grid.Column>
                </Menu.Item>

                <Menu.Item header color='yellow'>
                  Orders
                </Menu.Item>

                <Menu.Item
                  name='All'
                  to='/merchantOrder'
                  active={activeItem === 'All'}
                  onClick={this.handleItemClick}
                >
                  <Grid.Column>All</Grid.Column>
                </Menu.Item>

                <Menu.Item
                  name='Ongoing'
                  to='/merchantOrder/ongoing'
                  active={activeItem === 'Ongoing'}
                  onClick={this.handleItemClick}
                >
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={5}>Ongoing</Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Menu.Item>

                <Menu.Item
                  name='Past'
                  to='/merchantOrder/past'
                  active={activeItem === 'Past'}
                  onClick={this.handleItemClick}
                >
                  <Grid.Column>Past</Grid.Column>
                </Menu.Item>
              </Menu>
            </Grid.Column>

            {activeItem === 'Home' && (
              <Grid.Column width={13} style={{ paddingRight: 40 }}>
                <MerchantHome merchantInfo={s.userInfo} />
              </Grid.Column>
            )}

            {activeItem === 'Store' && (
              <Grid.Column width={13} style={{ paddingRight: 40 }}>
                <MerchantStore />
              </Grid.Column>
            )}

            {activeItem === 'products' && (
              <Grid.Column width={13} style={{ paddingRight: 40 }}>
                <MerchantProduct activeItem={activeItem} />
              </Grid.Column>
            )}

            {activeItem === 'accessaries' && (
              <Grid.Column width={13} style={{ paddingRight: 40 }}>
                <MerchantProduct activeItem={activeItem} />
              </Grid.Column>
            )}

            {activeItem === 'sale' && (
              <Grid.Column width={13} style={{ paddingRight: 40 }}>
                <MerchantProduct activeItem={activeItem} />
              </Grid.Column>
            )}

            {activeItem === 'newClothing' && (
              <Grid.Column width={13} style={{ paddingRight: 40 }}>
                <NewProduct activeItem='newClothing' />
              </Grid.Column>
            )}

            {activeItem === 'newAccessory' && (
              <Grid.Column width={13} style={{ paddingRight: 40 }}>
                <NewProduct activeItem='newAccessory' />
              </Grid.Column>
            )}

            {activeItem === 'Clothing' && (
              <Grid.Column width={13} style={{ paddingRight: 40 }}>
                <EditProduct activeItem='Clothing' />
              </Grid.Column>
            )}

            {activeItem === 'Accessory' && (
              <Grid.Column width={13} style={{ paddingRight: 40 }}>
                <EditProduct activeItem='Accessory' />
              </Grid.Column>
            )}

            {activeItem === 'Sale' && (
              <Grid.Column width={13} style={{ paddingRight: 40 }}>
                <EditProduct activeItem='Sale' />
              </Grid.Column>
            )}

            {activeItem === 'SaleClothing' && (
              <Grid.Column width={13} style={{ paddingRight: 40 }}>
                <ToSaleProduct activeItem='SaleClothing' />
              </Grid.Column>
            )}

            {activeItem === 'SaleAccessory' && (
              <Grid.Column width={13} style={{ paddingRight: 40 }}>
                <ToSaleProduct activeItem='SaleAccessory' />
              </Grid.Column>
            )}

            {activeItem === 'All' && (
              <Grid.Column width={13} style={{ paddingRight: 40 }}>
                <MerchantOrder activeItem='All' />
              </Grid.Column>
            )}

            {activeItem === 'Ongoing' && (
              <Grid.Column width={13} style={{ paddingRight: 40 }}>
                <MerchantOrder activeItem='Ongoing' />
              </Grid.Column>
            )}

            {activeItem === 'Past' && (
              <Grid.Column width={13} style={{ paddingRight: 40 }}>
                <MerchantOrder activeItem='Past' />
              </Grid.Column>
            )}
          </Grid>
        )}

        <Foot />
      </div>
    )
  }
}

export default withRouter(MerchantPage)