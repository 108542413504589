import React, { useState } from 'react'
import { useStripeConnect } from './useStripeConnect'
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider
} from '@stripe/react-connect-js'
import axios from 'axios'
import { Grid, Header, Segment, Button } from 'semantic-ui-react'

//live:
const BACKEND_URL = 'https://sheyou-backend.herokuapp.com'
//test
// const BACKEND_URL = 'http://localhost:1337';

export default function MerchantOnboard () {
  const [accountCreatePending, setAccountCreatePending] = useState(false)
  const [onboardingExited, setOnboardingExited] = useState(false)
  const [error, setError] = useState(false)
  const [connectedAccountId, setConnectedAccountId] = useState()
  const stripeConnectInstance = useStripeConnect(connectedAccountId)
  //live
  const token = localStorage.getItem('token')
  //testing
  // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NDAsImlhdCI6MTcyOTYyODAwNiwiZXhwIjoxNzI5NzE0NDA2fQ.U8N78mDmkbtwYD8tFcMrQRIjAWRihxw8g1QC1J9iMS8'

  // Add custom appearance configuration
  const appearance = {
    theme: 'flat',
    variables: {
      colorPrimary: '#f2711c', // Semantic UI's orange
      colorBackground: '#ffffff',
      colorText: 'rgba(0, 0, 0, 0.87)',
      colorDanger: '#db2828', // Semantic UI's red
      fontFamily: 'Lato, "Helvetica Neue", Arial, Helvetica, sans-serif',
      fontSizeBase: '14px',
      borderRadius: '4px',
      spacingUnit: '4px'
    },
    elements: {
      button: {
        backgroundColor: '#f2711c',
        color: '#ffffff',
        '&:hover': {
          backgroundColor: '#f26202'
        }
      }
    }
  }

  return (
    <div
      className='gradient_limeade_merchant'
      style={{ padding: '40px 20px', minHeight: '100vh' }}
    >
      <Segment>
        <Grid centered style={{ padding: '40px 20px' }}>
          <Grid.Row>
            <Header as='h1' style={{ marginBottom: '30px' }}>
              SHEYOU Merchant Onboarding
            </Header>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column textAlign='center'>
              {!connectedAccountId && (
                <>
                  <Header as='h2'>Get Started with SHEYOU</Header>
                  <p style={{ fontSize: '1.2em', margin: '20px 0' }}>
                    Join our next-generation fashion renting platform
                  </p>
                </>
              )}

              {connectedAccountId && !stripeConnectInstance && (
                <Header as='h2'>Complete your account setup</Header>
              )}

              {!accountCreatePending && !connectedAccountId && (
                <Button
                  size='large'
                  color='teal'
                  onClick={async () => {
                    setAccountCreatePending(true)
                    setError(false)
                    axios(`${BACKEND_URL}/pay/stripeAccount`, {
                      method: 'post',
                      headers: {
                        Authorization: 'Bearer ' + token
                      }
                    })
                      .then(response => {
                        console.log(response)
                        const { account, error, accountData } = response.data
                        console.log('account creation: ' + accountData)
                        var userInfo = localStorage.getItem('userInfo')
                        userInfo = JSON.parse(userInfo)
                        const userId = userInfo[0].id
                        axios(`${BACKEND_URL}/users/${userId}`, {
                          method: 'put',
                          headers: {
                            Authorization: 'Bearer ' + token
                          },
                          data: {
                            stripeAccount: account,
                            stripeAccountDetail: accountData
                          }
                        })
                          .then(response => {
                            console.log(
                              'User updated with Stripe account:',
                              response.data
                            )
                          })
                          .catch(error => {
                            console.log(
                              'Error updating user with Stripe account:',
                              error
                            )
                          })

                        if (account) {
                          setConnectedAccountId(account)
                        }
                      })
                      .catch(error => {
                        console.log(error)
                        setError(true)
                      })
                  }}
                >
                  Start Onboarding
                </Button>
              )}
            </Grid.Column>
          </Grid.Row>

          {stripeConnectInstance && (
            <Grid.Row>
              <ConnectComponentsProvider
                connectInstance={stripeConnectInstance}
              >
                <ConnectAccountOnboarding
                  onExit={() => setOnboardingExited(true)}
                  appearance={appearance}
                />
              </ConnectComponentsProvider>
            </Grid.Row>
          )}

          {error && (
            <Grid.Row>
              <p style={{ color: 'red' }}>
                Something went wrong! Please try again.
              </p>
            </Grid.Row>
          )}

          {(connectedAccountId || accountCreatePending || onboardingExited) && (
            <Grid.Row>
              <Segment basic textAlign='center'>
                {connectedAccountId && (
                  <p>
                    Connected account ID:{' '}
                    <code style={{ fontWeight: 'bold' }}>
                      {connectedAccountId}
                    </code>
                  </p>
                )}
                {accountCreatePending && <p>Setting up your account...</p>}
                {onboardingExited && <p>Onboarding process completed</p>}
              </Segment>
            </Grid.Row>
          )}
        </Grid>
      </Segment>
    </div>
  )
}
